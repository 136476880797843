import React from 'react';
import HomeLayout from 'pages/index';

export default class HomeID extends React.Component {
	render() {
		return(
			<HomeLayout langID={true} />
		);
	}
}
